import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';

import Template from './../../layouts/Template';
import { NowSection } from './_StyledNow';

const Now = ({ data }) => (
	<Template title={`Now | ${data.site.siteMetadata.title}`} desc="What I'm Doing Now...">
		<NowSection>
			<div className="wrapper">
				<div className="container">
					<div className="box">
						<div className="now-column">
							<h1 className="title-big">Now</h1>
							<div className="subtitle">LAST UPDATED May 27, 2021</div>
							<p>I am the manager of web development at Top Floor - it's been a wild couple of years!</p>
							<h2>Fun Stuff</h2>
							<p>I have been tiling a lot lately! Kitchen backsplashes, fireplaces, you name it.</p>
						</div>
					</div>
				</div>
			</div>
		</NowSection>
	</Template>
);

export default Now;

export const pageQuery = graphql`
	query NowQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
