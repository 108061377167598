import styled from 'styled-components';
export const NowSection = styled.section`
	${tw`pt-24`};
	.now-column {
		max-width: 724px;
	}
	.box {
		${tw`mx-auto text-left`};
	}
	.title-big {
		${tw`sm:text-128`};
		top: -0.55em;
		margin-bottom: -0.35em;
	}
	.subtitle {
		${tw`mb-4`};
	}
`;
